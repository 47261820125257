<template lang="pug">
  header.sticky-top
    #globalhead
      nav.navbar.navbar-expand-lg.navbar-dark
        router-link.navbar-brand(:to="{ name: 'Home' }")
          img(src='https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridat/branding/white/touridat-branding-white-50x50.png' width="25" height="25" alt="tD")
        button.navbar-toggler(type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation")
          span.navbar-toggler-icon
        #mainNav.collapse.navbar-collapse
          ul.navbar-nav.mr-auto
            li.nav-item.dropdown
              a#searchDropdown.nav-link.dropdown-toggle(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
                | Suche
              .dropdown-menu(aria-labelledby='searchDropdown')
                router-link.dropdown-item(:to="{ name: 'HostsList' }") Gastgeber
                router-link.dropdown-item(:to="{ name: 'ProductList' }") Produkte

            li.nav-item
              router-link.nav-link(:to="{ name: 'NewslettersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Sales || p.Id == PermissionsEnum.Admin)") Einkauf
            li.nav-item
              router-link.nav-link(:to="{ name: 'MarketingOverview' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Marketing || p.Id == PermissionsEnum.Admin)") Marketing
            li.nav-item
              router-link.nav-link(:to="{ name: 'OrdersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Service || p.Id == PermissionsEnum.Admin)") Service
            li.nav-item
              router-link.nav-link(:to="{ name: 'MolossCategoriesList' }") Moloss
            li.nav-item
              router-link.nav-link(:to="{ name: 'AdminEvaluations' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Admin)") Admin
            li.nav-item.dropdown
              a#usersDropdown.nav-link.dropdown-toggle(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
                | Benutzer
              .dropdown-menu(aria-labelledby='usersDropdown')
                router-link.dropdown-item(:to="{ name: 'ProductManagerUsersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Admin)") Produktmanager
                router-link.dropdown-item(:to="{ name: 'DashboardUsersList' }" v-if="$session.get('permissions').find(p => p.Id == PermissionsEnum.Sales || p.Id == PermissionsEnum.Admin)") Dashboard

          ul.navbar-nav.ml-auto
            li.nav-item
              a.header-button.txt-white.d-lg-flex.d-none(@click="openCallbacksModal")
                font-awesome-icon(:icon="['fas', 'phone']")
                p.badge.badge-danger.rounded-circle {{ notDoneCallbacksCount }}
              a.nav-link.d-flex.d-lg-none(@click="openCallbacksModal") Rückrufe
                p.badge.badge-danger.rounded-circle.ml-1(v-if="notDoneCallbacksCount") {{ notDoneCallbacksCount }}

            li.nav-item
              a.header-button.txt-white.d-none.d-lg-flex(@click="openNotificationsModal")
                font-awesome-icon(:icon="['fas', 'bell']")
                p.badge.badge-danger.rounded-circle(v-if="unseenNotificationsCount") {{ unseenNotificationsCount }}
              a.nav-link.d-flex.d-lg-none(@click="openNotificationsModal") Benachrichtigungen
                p.badge.badge-danger.rounded-circle.ml-1 {{ unseenNotificationsCount }}

            li.nav-item
              router-link.header-button.txt-white.d-none.d-lg-flex(:to="{ name: 'MailboxFolderMails', params: { mailboxId: mailbox.Id, mailboxFolderId: MailboxFoldersEnum.Inbox } }" v-if="mailbox")
                font-awesome-icon(:icon="['fas', 'envelope']")
                p.badge.badge-danger.rounded-circle(v-if="unreadMailsCount") {{ unreadMailsCount }}
              a.nav-link.d-flex.d-lg-none(@click="openNotificationsModal") E-Mails
                p.badge.badge-danger.rounded-circle.ml-1(v-if="unreadMailsCount") {{ unreadMailsCount }}

            li.nav-item.mt-1(v-if="user")
              a#userDropdown.nav-link.dropdown-toggle(href='#' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
                | {{ user.FirstName }}
              .dropdown-menu(aria-labelledby='userDropdown')
                router-link.dropdown-item(:to="{ name: 'UserDetails', params: { userId: user.Id }, query: { isDashboardUser: user.IsDashboardUser, redirectUrl: $router.currentRoute.fullPath } }") Benutzer
                router-link.dropdown-item(:to="{ name: 'UserProducts', params: { userId: user.Id } }") Produkte
                router-link.dropdown-item(:to="{ name: 'UserEvaluations', params: { userGuid: user.Guid } }") Auswertungen
                router-link.dropdown-item(:to="{ name: 'UserContracts', params: { userGuid: user.Guid } }") Verträge
                router-link.dropdown-item(:to="{ name: 'NotificationsList', params: { userId: user.Id } }") Benachrichtigungen
                router-link.dropdown-item(:to="{ name: 'AppointmentsList', params: { userId: user.Id } }") Termine
                a.dropdown-item(@click="logout") Abmelden

</template>

<script>
import EventBus from "../../../event-bus";
import { PermissionsEnum } from "@/utils/enums/permissions/PermissionsEnum";
import NotificationsModal from "@/views/components/notifications/NotificationsModal";
import { NotificationTypesEnum } from "@/utils/enums/notificationTypes/NotificationTypesEnum.ts";
import CallbacksModal from "@/views/components/callbacks/CallbacksModal.vue";
import { NotificationsComponent } from "@/lib/components/notifications/NotificationsComponent.ts";
import { CallbacksComponent } from "@/lib/components/callbacks/CallbacksComponent.ts";
import { UsersComponent } from "@/lib/components/users/UsersComponent.ts";
import { MailboxesComponent } from "@/lib/components/mailboxes/MailboxesComponent.ts";
import moment from "moment/moment";
import { MailsComponent } from "@/lib/components/mails/MailsComponent.ts";
import { MailTypesEnum } from "@/utils/enums/mailTypes/MailTypesEnum.ts";
import { MailboxFoldersEnum } from "@/utils/enums/mailboxFolders/MailboxFoldersEnum.ts";

export default {
  name: "Header",
  data() {
    return {
      unseenNotificationsCount: 0,
      notificationsCount: 0,
      permissions: this.$session.get("permissions"),
      user: undefined,

      notDoneCallbacksCount: 0,
      mailbox: undefined,

      unreadMailsCount: 0,

      PermissionsEnum: PermissionsEnum,
      MailboxFoldersEnum: MailboxFoldersEnum,
    };
  },
  async mounted() {
    try {
      EventBus.$on("updateNotDoneNotificationsCount", () => {
        this.loadNotDoneNotificationsCount();
      });
      EventBus.$on(
        "updateCallbacks",
        function () {
          this.loadCallbacksCount();
        }.bind(this)
      );
      this.loadNotDoneNotificationsCount();
      this.loadUser();
      this.loadCallbacksCount();
      this.loadMailbox();
      setInterval(this.checkNotifications, 2 * 60 * 1000); // min * secs * milsecs
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    logout() {
      try {
        this.$session.destroy();
        EventBus.$emit("changeLoginState", false);
        this.$router.push("/login").catch(() => {});
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadNotificationsCount() {
      try {
        const notificationsComponent = new NotificationsComponent();
        const notificationsCount =
          await notificationsComponent.getNotificationsCount(
            this.$apollo,
            this.$session.get("userId"),
            undefined,
            undefined,
            undefined,
            undefined,
            NotificationTypesEnum.ProductManager
          );
        if (notificationsCount == undefined) {
          return;
        }
        this.notificationsCount = notificationsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadNotDoneNotificationsCount() {
      try {
        const notificationsComponent = new NotificationsComponent();
        const unseenNotificationsCount =
          await notificationsComponent.getNotificationsCount(
            this.$apollo,
            this.$session.get("userId"),
            undefined,
            undefined,
            undefined,
            undefined,
            NotificationTypesEnum.ProductManager,
            undefined,
            false
          );
        if (unseenNotificationsCount == undefined) {
          return;
        }
        this.unseenNotificationsCount = unseenNotificationsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadCallbacksCount() {
      try {
        const callbacksComponent = new CallbacksComponent();
        const callbacksCount = await callbacksComponent.getCallbacksCount(
          this.$apollo,
          false
        );
        if (callbacksCount == undefined) {
          return;
        }
        this.notDoneCallbacksCount = callbacksCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    openNotificationsModal() {
      try {
        this.$modal.show(
          NotificationsModal,
          {
            userId: this.$session.get("userId"),
          },
          {
            height: "auto",
            classes: ["rounded-0", "w-auto"],
          }
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openCallbacksModal() {
      try {
        this.$modal.show(
          CallbacksModal,
          {},
          {
            height: "auto",
            classes: ["rounded-0", "w-auto"],
          }
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async checkNotifications() {
      try {
        const notificationsComponent = new NotificationsComponent();
        const notificationsCount =
          await notificationsComponent.getNotificationsCount(
            this.$apollo,
            this.$session.get("userId"),
            undefined,
            undefined,
            undefined,
            undefined,
            NotificationTypesEnum.ProductManager,
            undefined,
            undefined,
            moment().subtract(2, "minutes").toDate()
          );
        if (!notificationsCount) {
          return;
        }
        this.loadNotDoneNotificationsCount();
        this.notificateLastUndoneNotificationByUserId();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async notificateLastUndoneNotificationByUserId() {
      try {
        const notificationsComponent = new NotificationsComponent();
        const notifications = await notificationsComponent.getNotifications(
          this.$apollo,
          this.$session.get("userId"),
          undefined,
          undefined,
          undefined,
          undefined,
          NotificationTypesEnum.ProductManager,
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          1
        );
        if (!notifications) {
          return;
        }
        if (notifications.length == 0) {
          return;
        }
        const lastNotification = notifications[0];
        if (!lastNotification) {
          return;
        }
        this.$notification.show(
          "Produktmanager",
          {
            body: lastNotification.Title,
          },
          {}
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadUser() {
      try {
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
          this.$apollo,
          this.$session.get("userId")
        );
        if (!user) {
          return;
        }
        this.user = user;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadMailbox() {
      try {
        const mailboxesComponent = new MailboxesComponent();
        const mailbox = await mailboxesComponent.getMailboxByUserId(
          this.$apollo,
          this.$session.get("userId")
        );
        if (!mailbox) {
          return;
        }
        this.mailbox = mailbox;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadMailsCount() {
      try {
        const mailsComponent = new MailsComponent();
        const mailsCount = await mailsComponent.getMailsCount(
          this.$apollo,
          this.mailbox.Id,
          undefined,
          MailTypesEnum.Inbox,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false
        );
        if (mailsCount == undefined) {
          return;
        }
        this.unreadMailsCount = mailsCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    mailbox() {
      try {
        if (!this.mailbox) {
          return;
        }
        this.loadMailsCount();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown-menu.show {
  display: block;
  width: 100%;
  border-radius: 0;
  background-color: #333333;
  border: none;
  margin-top: -2px;

  a {
    color: rgba(255, 255, 255, 0.5);
    padding-left: 15px;
    padding-right: 15px;
  }

  a:hover {
    color: #fff;
    background-color: #333333;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #333333;
}
</style>
